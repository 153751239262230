.content-wrapper {
    padding-top: 105px;
    padding-bottom: 50px;
}

.content-wrapper.no-submenu {
    padding-top: 80px;
}

@media (max-width: 768px) {
    .content-wrapper {
        padding-top: 155px;
        /*padding-bottom: 30px;
        min-height: 800px;*/
    }

    .content-wrapper.no-submenu {
        padding-top: 120px;
    }
}
