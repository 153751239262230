footer {
	background: #f8f9fa;
	color: #646464;
	position: absolute;
	width: 100%;
	bottom: 0;
	text-align: center;
	z-index: 100;
	border-top: 1px solid #EDEBF1;
}

footer.impostor-info {
	background-color: #FE4863;
	color: #fff;
	position: fixed;
}

footer a {
	color: #00D283;
}

footer a:hover {
	color: #81DEBC;
}

footer ul {
	list-style: none;
	padding: 0;
}

footer .btn-primary {
	display: inline-block;
	margin-top: 10px;
	color: #fff;
}

footer h4 {
	color: #ffffff;
}

footer .copyright {
	margin-top: 20px;
    font-weight: 400;
}

@media (max-width: 768px) {
	footer {
		/*position: static;*/
	}
}
