/* Color Palette
#493780 - Dark purple
#7361A9 - Medium purple
#9184B9 - Light purple
#DCD5F1 - Very light purple
#EDEBF1 - Extremely light purple
#1BB47A - Dark green
#00D283 - Bright green
#81DEBC - Light green
#D3F1E6 - Very light green
#DB344D - Dark pink
#FE4863 - Bright pink
#FE768A - Medium pink
#FEA3B1 - Light pink
#FFD1D8 - Very light pink
#FFA43D - Orange
#FEC533 - Yellow
#FFD19E - Beige
#FBEFE9 - Very light beige
#000000 - Black
#646464 - Dark Grey
#AAAAAA - Medium Grey
#CCCCCC - Light Grey
#DEDEDE - Border Grey
#EEEEEE - Very Light Grey
 */

/* Base */

html {
    height: 100%;
}

body {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    /*font-weight: 400;*/
    min-height: 100%;
    position: relative;
    color: #646464;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
}

h1, h2, h3 {
    color: #00D283;
}

h4, h5, h6 {
    color: #7361A9;
}

h2 {
    margin-bottom: 40px;
}

h2:after {
    content: "";
    display: block;
    height: 2px;
    width: 100px;
    background: #493780;
    margin-top: 10px;
}

h3 {
    margin-bottom: 20px;
}

a {
    color: #00D283;
}

a:focus, a:hover {
    color: #81DEBC;
    text-decoration: none;
    transition: all 0.3s linear;
}

b, strong {
    font-weight: 500;
}

.small, small {
    font-weight: 400;
}

/* Buttons */

.btn {
    border-radius: 50px;
    font-weight: 400;
}

.btn-primary {
    color: #fff;
    background-color: #00D283;
    border-color: #00D283;
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.3s linear;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #81DEBC;
    border-color: #81DEBC;
}

.btn:focus,
.btn.focus,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-inverse {
    color: #00D283;
    background-color: transparent;
    border-color: #00D283;
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.3s linear;
}

.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:focus,
.btn-inverse.disabled,
.btn-inverse:disabled,
.btn-inverse.active.focus,
.btn-inverse.active:focus,
.btn-inverse.active:hover,
.btn-inverse:active.focus,
.btn-inverse:active:focus,
.btn-inverse:active:hover,
.open > .dropdown-toggle.btn-inverse.focus,
.open > .dropdown-toggle.btn-inverse:focus,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:not(:disabled):not(.disabled):active,
.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #00D283;
    border-color: #00D283;
}

.btn:focus,
.btn.focus,
.btn-inverse:focus,
.btn-inverse.focus,
.btn-inverse:not(:disabled):not(.disabled):active:focus,
.btn-inverse:not(:disabled):not(.disabled).active:focus,
.show > .btn-inverse.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-secondary {
    color: #fff;
    background-color: #493780;
    border-color: #493780;
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.3s linear;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary:active.focus,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7361A9;
    border-color: #7361A9;
}

.btn-secondary-inverse {
    color: #493780;
    background-color: transparent;
    border-color: #493780;
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.3s linear;
}

.btn-secondary-inverse:hover,
.btn-secondary-inverse:active,
.btn-secondary-inverse.active,
.btn-secondary-inverse.focus,
.btn-secondary-inverse:focus,
.btn-secondary-inverse.disabled,
.btn-secondary-inverse:disabled,
.btn-secondary-inverse.active.focus,
.btn-secondary-inverse.active:focus,
.btn-secondary-inverse.active:hover,
.btn-secondary-inverse:active.focus,
.btn-secondary-inverse:active:focus,
.btn-secondary-inverse:active:hover,
.open > .dropdown-toggle.btn-secondary-inverse.focus,
.open > .dropdown-toggle.btn-secondary-inverse:focus,
.open > .dropdown-toggle.btn-secondary-inverse:hover,
.btn-secondary-inverse:not(:disabled):not(.disabled):active,
.btn-secondary-inverse:not(:disabled):not(.disabled).active,
.show > .btn-secondary-inverse.dropdown-toggle {
    color: #fff;
    background-color: #493780;
    border-color: #493780;
}

.btn-secondary-inverse:disabled {
    color: #fff;
    background-color: #7361A9;
    border-color: #7361A9;
}

.btn:focus,
.btn.focus,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn.btn-back {
    padding: 5px 10px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1;
}

.btn.btn-title {
    font-size: 14px;
    padding: 3px 15px;
    margin-left: 10px;
}

*:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
.page-link:focus {
    outline: none;
    box-shadow: none;
}

.btn-danger {
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.3s linear;
    font-weight: 400;
    background-color: #FE4863;
    border-color: #FE4863;
}

.btn-danger:hover {
    background-color: #DB344D;
    border-color: #DB344D;
}

.btn-danger-inverse {
    color: #FE4863;
    background-color: transparent;
    border-color: #FE4863;
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.3s linear;
}

.btn-danger-inverse:hover {
    color: #fff;
    background-color: #FE4863;
    border-color: #FE4863;
}

.btn-link,
.page-link {
    color: #00D283;
}

.btn-link:hover,
.page-link:hover {
    color: #81DEBC;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.focus {
    text-decoration: none;
}

.page-item.active .page-link {
    background-color: #00D283;
    border-color: #00D283;
}

/*.page-content {
    padding-bottom: 174px;
}

.row-block {
    padding: 40px 0;
}*/

/* Generic Landing Pages */

.landing-strip {
    /*background: url("./pages/Home/home-bg.jpg") no-repeat center #e6e6e6;
    background-attachment: fixed;
    background-size: cover;*/
    background: #FBEFE9;
    padding: 120px 0 40px;
    margin: 0 -15px 20px;
}

/* Forms */

.form-component {
    position: relative;
    width: 100%;
}

.form-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 5;
}

.form-loading i {
    top: 50%;
    margin-top: -10px;
    display: block;
    position: relative;
    z-index: 10;
}

input,
button,
select,
optgroup,
textarea {
    color: #646464;
}

input::placeholder,
textarea::placeholder {
    color: #aaa;
}

input:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
    color: #ccc;
}

input,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="password"],
textarea,
select {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    box-shadow: none;
    padding: 8px 20px;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    font-weight: 400;
}

input[type=checkbox],
input[type=radio] {
    margin: 0 10px 0 0;
    width: auto;
    display: inline-block;
}

input[type='radio']:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #fff;
    content: '';
    display: block;
    border: 1px solid #DEDEDE;
    margin-top: -2px;
    margin-left: -2px;
}

input[type='radio']:checked:after {
    border-radius: 50%;
    font-family: "Font Awesome 5 Free";
    content: "\f192";
    font-weight: 900;
    font-size: 19px;
    line-height: 19px;
    border: none;
    color: #00D283;
}

input[type='checkbox']:after {
    width: 17px;
    height: 17px;
    background-color: #fff;
    content: '';
    display: block;
    border: 1px solid #DEDEDE;
    margin-top: -2px;
}

input[type='checkbox']:checked:after {
    padding: 3px;
    background-color: #00D283;
    color: #fff;
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    font-weight: 900;
    font-size: 11px;
    line-height: 12px;
    border: none;
}

label[for] {
    cursor: pointer;
}

textarea {
    resize: vertical;
    width: 100%;
    min-height: 100px;
}

select {
    color: #aaa;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url("./img/down.svg") no-repeat right 20px top 50% #fff;
}

/*input[type="date"] {
    color: #aaa;
}*/

input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
    content: "";
}

input[type="search"] {
    max-width: 300px;
    border-radius: 50px;
    display: inline-block;
    font-size: 14px;
}

.error {
    color: #FE4863;
}

.success {
    color: #00D283;
}

.form-component .characters-count {
    display: block;
    text-align: right;
    margin-top: -15px;
    font-size: 9px;
    padding-bottom: 2px;
}

.form-component .checkbox-group {
    margin-bottom: 10px;
}

.form-component .checkbox-group label {
    margin-bottom: 3px;
    font-size: 14px;
}

.form-component .buttons-wrapper {
    text-align: right;
}

.form-component .buttons-wrapper .btn {
    margin-right: 5px;
}

.form-component .buttons-wrapper .btn:last-child {
    margin-right: 0;
}

/* Loader */

.fa-spin {
    font-size: 16px;
    color: #00D283;
}

/* Modals */

.modal-header {
    border-bottom: none;
}

.modal-title {
    color: #00D283;
    font-size: 1.75rem;
}

.modal-footer {
    border-top: none;
}

/* Tables */

.table {
    color: #646464;
}

.react-bootstrap-table .table {
    table-layout: auto;
}

th {
    font-weight: 500;
}

.react-bootstrap-table .table > tbody > tr > td,
.react-bootstrap-table .table > tbody > tr > th,
.react-bootstrap-table .table > tfoot > tr > td,
.react-bootstrap-table .table > tfoot > tr > th,
.react-bootstrap-table .table > thead > tr > td,
.react-bootstrap-table .table > thead > tr > th {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-bootstrap-table table > thead {
    text-transform: uppercase;
    font-size: 14px;
}

.react-bootstrap-table .react-bootstrap-table-sort-order,
.react-bootstrap-table .order,
.react-bootstrap-table .sortable > span {
    margin-left: 5px;
    float: right;
}

.react-bootstrap-table .order .dropdown {
    display: none;
}

.react-bootstrap-table .btn-link {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.react-bootstrap-table .btn {
    font-size: 12px;
    padding: 3px 10px;
    margin-right: 5px;
}

.react-bootstrap-table .btn:last-child {
    margin-right: 0;
}

.react-bootstrap-table .btn i {
    margin-right: 5px;
}

.react-bootstrap-table-pagination {
    margin: 0;
}

.react-bootstrap-table-pagination > div {
    padding-left: 0;
}

.react-bootstrap-table-pagination-list {
    text-align: right;
    padding-right: 0;
}

.react-bootstrap-table-pagination-list .pagination {
    margin: 0;
}

.react-bootstrap-table-pagination-list .page-item:first-child .page-link {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.react-bootstrap-table-pagination-list .page-item:last-child .page-link {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.table-tools {
    float: right;
    margin-bottom: 20px;
}

.table-tools .btn {
    padding: 6px 9px;
    border-radius: 100px;
    color: #00D283;
    background: #fff;
    border-color: #ddd;
    margin-left: 5px;
}

.table-tools .btn:hover {
    color: #aaa;
}

/* Select */

.react-select-container {
    margin-bottom: 15px;
}

.react-select-container .react-select__input,
.react-select-container .react-select__single-value {
    color: #646464;
}

.react-select-container .react-select__control {
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    background: none;
    min-height: 20px;
}

.react-select-container .react-select__control:hover {
    border: 1px solid #DEDEDE;
}

.react-select-container .react-select__control--is-focused {
    box-shadow: none;
}

.react-select-container .react-select__value-container {
    padding: 5px 20px;
    max-height: 40px;
    overflow-y: auto;
}

.react-select-container .react-select__indicator-separator {
    /*display: none;*/
    color: #999;
}

.react-select-container .react-select__dropdown-indicator {
    color: #999;
    /*padding: 0;*/
}

.react-select-container .react-select__input input[type="text"] {
    margin-bottom: 0;
}

.react-select-container .react-select__placeholder {
    color: #aaa;
}

.react-select-container.react-select--is-disabled .react-select__placeholder,
.react-select-container.react-select--is-disabled .react-select__input,
.react-select-container.react-select--is-disabled .react-select__single-value {
    color: #ccc;
}

.react-select-container .react-select__menu {
    z-index: 10;
}

.react-select-container .react-select__menu-list {
    /*padding: 0;*/
}

.react-select-container .react-select__option {
    hyphens: auto;
}

.react-select-container .react-select__option:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.react-select-container .react-select__option:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.react-select-container .react-select__option:hover {
    background: #81DEBC;
    color: #fff;
}

.react-select-container .react-select__option--is-focused {
    background: #EDEBF1;
    color: #493780;
}

.react-select-container .react-select__option--is-selected {
    background: #00D283;
    color: #fff;
}

/* Sentiments Select */

.react-select-container.sentiments-select .react-select__option--is-focused {
    background: #7361A9;
    color: #fff;
}

.react-select-container.sentiments-select .react-select__option--is-selected {
    background: #fff;
    color: #493780;
    text-shadow: none;
}

.sentiments-select .react-select__option:hover {
    background: #7361A9;
    color: #fff;
}

/* Tiny Link */

a.react_tinylink_card {
    box-shadow: none;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    margin-bottom: 15px;
    color: #212529;
    font-family: 'DM Sans', sans-serif;
    line-height: 18px;
    max-width: 100%;
}

a.react_tinylink_card:hover {
    color: #00D283;
    background-color: #f8f9fa;
}

.react_tinylink_card .react_tinylink_card_media {
    background-color: #efefef;
}

.react_tinylink_card footer {
    background: none;
    color: #212529;
    transition: all 0.3s linear;
}

.react_tinylink_card:hover footer {
    color: #00D283;
}

/* Beautiful DND */

div[data-rbd-droppable-id="droppable"] {
    margin-bottom: 20px;
    width: 100%;
    box-shadow: none;
    padding: 8px 20px;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
}

div[data-rbd-draggable-context-id] {
    border-radius: 5px;
    margin: 20px 0;
    padding: 10px;
    position: relative;
}

div[data-rbd-draggable-context-id] textarea {
    background: transparent;
    border: none;
    min-height: 0;
    padding: 0 0 0 30px;
    margin: 0;
}

/* Rich Text Editor */

.rich-text-html-editor {
    margin-bottom: 20px;
    width: 100%;
    box-shadow: none;
    padding: 8px 20px;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
}

.html-editor-wrapper .public-DraftStyleDefault-block {
    margin: 0;
}

.html-editor-wrapper *,
.html-editor-wrapper *::before,
.html-editor-wrapper *::after {
    box-sizing: content-box;
}

.html-editor-wrapper .html-editor {
    /*height: 200px;*/
    padding: 10px 0;
}

.html-editor-wrapper .rdw-image-modal-size {
    display: none;
}

/* Alert */

.alert-success {
    color: #1BB47A;
    background-color: #D3F1E6;
    border-color: #D3F1E6;
}

/* Badges */

.badge {
    font-size: 11px;
    font-weight: 400;
    vertical-align: text-top;
}

.badge-primary {
    color: #00D283;
    border: 1px solid #00D283;
    background-color: transparent;
}

/* Tooltips */

.tooltip-inner {
    background-color: #646464;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #646464;
}

/* StepZilla */

ol.progtrckr {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
}

ol.progtrckr:after {
    content: "";
    display: block;
    background-image: linear-gradient(90deg, rgba(226, 228, 236, 1), rgba(145, 132, 185, 1));
    height: 10px;
    border-radius: 100px;
    margin-top: -10px;
}

ol.progtrckr li {
    display: inline-block;
    line-height: 60px;
}

ol.progtrckr li span {
    padding: 0 10px;
    font-size: 14px;
}

.progtrckr em {
    font-style: normal;
    padding: 3px 6px;
    border-radius: 50%;
    border: 1px solid;
    font-size: 12px;
    text-align: center;
    width: 24px;
    height: 24px;
    display: inline-block;
    line-height: 16px;
}

ol.progtrckr li {
    color: #493780;
    position: relative;
}

ol.progtrckr li:after {
    content: "";
    display: block;
    background-image: linear-gradient(90deg, rgba(226, 228, 236, 1), rgba(145, 132, 185, 1));
    height: 10px;
}

ol.progtrckr li:last-child:after {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

ol.progtrckr li.progtrckr-todo {
    color: #AAAAAA;
}

ol.progtrckr li.progtrckr-todo:after {
    background: #eee;
}

ol.progtrckr li.progtrckr-doing {
    font-weight: 400;
}

ol.progtrckr li.progtrckr-doing:after {
    background: transparent;
}

ol.progtrckr li.progtrckr-done:after {
    background: transparent;
}

ol.progtrckr li.progtrckr-done:before,
ol.progtrckr li:last-of-type.progtrckr-doing:before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-size: 12px;
    padding: 0 6px;
    top: 19px;
    line-height: 24px;
    border-radius: 50%;
    color: #fff;
    background-color: #493780;
}

.allow-jump-steps .multi-step ol.progtrckr li {
    cursor: pointer;
}

.multi-step ol.progtrckr li span {
    pointer-events: none;
    display: inline-block;
}

.multi-step .footer-buttons {
    margin-top: 30px;
}

/* Info Card */

.info-card {
    border: 1px solid #EDEBF1;
    transition: all .2s ease;
    background: #fafafa;
    border-radius: 10px;
    /*overflow: hidden;*/
    margin-bottom: 20px;
}

.info-card:hover {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
}

.info-card i {
    margin-right: 7px;
}

.info-card h5 {
    padding: 10px 20px;
    color: #493780;
    background: #EDEBF1;
    margin-bottom: 0;
    font-size: 18px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.info-card h5 span {
    font-weight: 400;
}

.info-card .card-content {
    padding: 10px 20px;
    border-bottom: 1px solid #EDEBF1;
    font-size: 14px;
}

.info-card .card-content:last-child {
    border-bottom: none;
}

.info-card .card-content p:last-child {
    margin-bottom: 0;
}

/* Coming Soon */

.coming-soon-img {
    text-align: center;
}

.coming-soon-img img {
    width: 100%;
    max-width: 400px;
}

/* Media Queries */

@media (max-width: 1300px) {
    ol.progtrckr li span {
        padding: 0 5px;
        font-size: 13px;
    }
}

@media (max-width: 992px) {
    .react-bootstrap-table .btn {
        line-height: 1.2;
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    .page-content {
        /*padding-bottom: 0;
        min-height: 600px;*/
    }

    .landing-strip h1 {
        font-size: 30px;
    }

    .landing-strip p {
        font-size: 15px;
    }

    h2 {
        margin-top: 0;
    }

    .form-component .btn {
        margin-bottom: 5px;
    }

    .btn.btn-title {
        display: block;
        margin: 10px 0 0;
    }

    input[type="search"] {
        max-width: 100%;
    }

    ol.progtrckr li {
        line-height: 20px;
    }

    ol.progtrckr li:after {
        margin-top: 10px;
    }

    ol.progtrckr li.progtrckr-done:before,
    ol.progtrckr li:last-of-type.progtrckr-doing:before {
        top: 0;
    }
}
