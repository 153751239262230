.sidebar-container {
    width: 100%;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 72px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.navbar-light.sidebar .navbar-nav {
    width: 100%;
}

.navbar-light.sidebar .navbar-nav .nav-link {
    font-size: 16px;
    font-weight: 400;
    padding: 0.5rem 1.3rem;
    border-bottom: 1px solid #eee;
}

.navbar-light.sidebar .navbar-nav .nav-link:hover {
    background: #efefef;
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 70px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link i {
    margin-right: 10px;
}

.sidebar .nav-link.active {
    color: #00D283;
}

.sidebar .btn-logout {
    text-align: left;
    border-radius: 0;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .sidebar {
        box-shadow: none;
        padding: 0.5rem 1rem;
        top: 114px;
        bottom: auto;
        display: block;
        z-index: 1030;
    }

    .sidebar.bg-light {
        background: none!important;
    }

    .sidebar-sticky {
         height: auto;
         padding-top: 0;
     }

    .navbar-light.sidebar .navbar-toggler {
        position: fixed;
        top: 15px;
        right: 15px;
    }

    .navbar-light.sidebar .navbar-nav {
        background: #f2f2f2;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .navbar-light.sidebar .navbar-nav .nav-link:last-child {
        margin-bottom: 0;
    }
}
