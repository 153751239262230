.login-logo {
    text-align: center;
}

.login-logo h3 {
    margin-bottom: 30px;
}

.login-logo img {
    width: 50px;
    height: auto;
}

.login-buttons .btn {
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.login-buttons .btn i {
    margin-right: 15px;
}

.section-divider-wrapper {
    text-align: center;
    overflow: hidden;
    margin-bottom: 20px;
}

.section-divider {
    position: relative;
    display: inline-block;
    padding: 0 15px;
}

.section-divider:before {
    position: absolute;
    content: "";
    top: 50%;
    right: 100%;
    width: 5000px;
    border-bottom: 1px solid #cccccc;
}

.section-divider:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 100%;
    width: 5000px;
    border-bottom: 1px solid #cccccc;
}

.login-form-fields button {
    display: block;
    margin: 15px 0;
    width: 100%;
}

.login-form-fields button.forgot-password-link {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0;
}

.password-reset-form-fields button {
    float: right;
}
