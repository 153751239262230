.route-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 5;
    top: 0;
    left: 0;
}

.route-loader-error {
    padding: 20px 0;
}

.route-loader-error .error {
    text-align: center;
}

.route-loader .route-loading {
    top: 50%;
    position: relative;
    z-index: 10;
    margin-top: -13px;
}