.bg-light {
    transition: background linear 0.2s;
}

.bg-light.light {
    background: transparent!important;
}

.bg-light.dark {
    background: #fcf9f7!important;
    border-bottom: 2px solid #81DEBC;
    /*box-shadow: 0 0 10px 1px #aaa;*/
}

.navbar-brand {
    min-height: 72px;
    padding: 16px 0;
}

.navbar-brand .logo-main {
    max-width: 40px;
    height: auto;
}

.navbar-brand a span {
    display: inline-block;
    margin-left: 10px;
    font-weight: 500;
    color: #000;
    vertical-align: middle;
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.navbar-light .navbar-nav .btn.btn-secondary {
    margin-right: 6px;
}

.navbar-light .navbar-nav .nav-link {
	color: #646464;
	font-family: 'DM Sans', sans-serif;
	font-weight: 400;
	font-size: 18px;
    padding: 0.3rem 1.3rem;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: #00D283;
}

.navbar-light .navbar-nav .nav-link.news-item-btn {
    border-radius: 50px;
    background-color: #00D283;
    color: #fff;
}

.navbar-light .navbar-nav .nav-link.news-item-btn:hover,
.navbar-light .navbar-nav .nav-link.news-item-btn:focus {
	background-color: #81DEBC;
	color: #fff;
}

.navbar-light.dark .navbar-nav .nav-link {
    color: #646464;
}

.navbar-light.dark .navbar-nav .nav-link:hover,
.navbar-light.dark .navbar-nav .nav-link.active {
    color: #81DEBC;
}

.navbar-light.dark .navbar-nav .nav-link.contact {
    color: #fff;
}

.navbar-light.dark .navbar-nav .nav-link.login:hover {
    border-color: #81DEBC;
}

.navbar-right {
    margin-top: 20px;
}

.navbar-light .navbar-toggler {
    border-color: #493780;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(73, 55, 128, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light.light .navbar-toggler {
    border-color: #493780;
}

.navbar-light.light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(73, 55, 128, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light.dark .navbar-toggler {
    border-color: #493780;
}

.navbar-light.dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(72, 55, 128, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media (max-width: 992px) {
    .navbar-light .navbar-nav .nav-link {
		padding-right: 10px;
		padding-left: 10px;
        font-size: 13px;
	}
}

@media (max-width: 768px) {
    .navbar-light .navbar-nav {
        width: 100%;
        flex-direction: row;
    }

    .navbar-light .navbar-nav .btn {
        margin-bottom: 20px;
        width: calc(100% - 3px);
        font-size: 12px;
        padding: 5px 10px;
    }

    .navbar-light .navbar-nav .nav-link {
        padding-right: 1.3em;
        padding-left: 1.3em;
        font-size: 18px;
    }

    .navbar-light.light .navbar-nav .nav-link {
        background-color: #fcf9f7;
    }

    .navbar-light.light .navbar-nav .nav-link.news-item-btn {
        background-color: #00D283;
        border-radius: 0 0 10px 10px;
    }

    .navbar-light .navbar-nav .nav-link:last-child,
    .navbar-light .navbar-nav .btn:last-child {
        margin-bottom: 20px;
    }

	.navbar-right {
		margin-bottom: 0;
	}

	.navbar-default .navbar-collapse {
		overflow: hidden!important;
	}

    .navbar-default .navbar-nav>li>a {
        padding-right: 15px;
        padding-left: 15px;
    }
}
