.login-form {
    max-width: 500px;
    margin: 40px auto;
    border: 1px solid #DEDEDE;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .login-form {
        max-width: calc(100% - 40px);
        margin: 40px 20px;
    }
}
